<template>
  <div class="wrap">
    <Navbar :title="$t('nav.activityDetails')" />
    <div class="content" v-html="content.content"></div>
  </div>
</template>

<script>
import { $post, $get } from "../../utils/request";
import { NavBar, Toast, Icon } from "vant";
import Navbar from "@/components/JNav";
export default {
  components: {
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
    Toast,
    Navbar,
  },
  data() {
    return {
      content: {},
    };
  },
  created() {
    const { id } = this.$route.query;
    this.getData(id);
  },
  methods: {
    async getData(id) {
      try {
        const res = await $post("/content/sale/info", { id });
        const { ret, msg, data } = res.data;
        Toast.clear();
        this.content = data;
      } catch (error) {
        Toast.clear();
      }
    },
  },
};
</script>

<style lang="less" scoped>
*{
  // color: var(--theme);
  color: var(--light);
}
.wrap {
  min-height: 100vh;
  background: var(--black);
  .content {
    padding: calc(5rem / 16);
  }
}
</style>